var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-autocomplete",
        {
          staticClass: "inline-input",
          style: _vm.sty,
          attrs: {
            "popper-class": _vm.popperClass,
            "fetch-suggestions": _vm.querySearch,
            "value-key": "cust_name",
            placeholder: "请输入客户名称、简拼",
            disabled: _vm.disab,
          },
          on: { select: _vm.handleSelect, input: _vm.handleInput },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c("div", { staticClass: "wrap" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(" " + _vm._s(item.cust_name) + " "),
                    ]),
                    _c("div", { staticClass: "addr" }, [
                      _vm._v(_vm._s(item.boss) + " " + _vm._s(item.mobile)),
                    ]),
                  ]),
                ]
              },
            },
          ]),
          model: {
            value: _vm.kw,
            callback: function ($$v) {
              _vm.kw = $$v
            },
            expression: "kw",
          },
        },
        [
          _vm.search
            ? _c("i", {
                staticClass: "el-input__icon el-icon-search",
                attrs: { slot: "suffix" },
                on: { click: _vm.iconClick },
                slot: "suffix",
              })
            : _vm._e(),
        ]
      ),
      _c("choose-client", {
        ref: "chooseClient",
        attrs: { ziobj: _vm.ziobj },
        on: { "change-client": _vm.setInput },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }