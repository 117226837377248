//渠道
import request from '@/utils/request'

// 表格数据   &&&  客户简拼下拉数据
export function getList(data) {
  return request({
    url: '/baseAdmin/customer-info/window-view-do',
    method: 'post',
    data,
  })
}

// 下拉数据
export function getSelect(data) {
  return request({
    url: '/baseAdmin/customer-info/window-view',
    method: 'post',
    data,
  })
}
