<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="选择客户"
      top="5vh"
      :visible.sync="showDialog"
      width="1050px"
      append-to-body
      @close="close"
    >
      <div v-loading="loading" class="wrapper">
        <el-form
          ref="form"
          label-width="80px"
          :inline="true"
          style="margin-bottom: 10px"
        >
          <el-form-item>
            <el-input
              v-model="queryForm.keyword"
              placeholder="输入门店,编号"
              style="width: 250px; margin: 0px 10px 0px 0"
            ></el-input>
          </el-form-item>
          <el-form-item label="片区">
            <el-cascader
              v-model="queryForm.area_id"
              clearable
              filterable
              :options="form.area"
              :props="props"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="渠道" style="margin: 0 0 0 10px">
            <el-select
              v-model="queryForm.channel_id"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in form.channel"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button style="margin: 0 10px" @click="filter">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table
          stripe
          :data="list"
          height="450px"
          @selection-change="setSelectRows"
          @cell-click="cellClick"
        >
          <!-- 选择框 -->
          <!-- <el-table-column
          align="center"
          type="selection"
          width="60"
        ></el-table-column> -->
          <!-- 序号 -->
          <el-table-column prop="" align="center" label="序号" min-width="50px">
            <template #default="{ $index }">
              <span>{{ $index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="cust_name"
            align="center"
            label="客户名称"
            width=""
          ></el-table-column>
          <el-table-column
            prop="cust_pinyin"
            align="center"
            label="助记码"
            width=""
          ></el-table-column>
          <el-table-column
            prop="boss"
            align="center"
            label="老板名称"
            width=""
          ></el-table-column>
          <el-table-column
            prop="mobile"
            align="center"
            label="老板手机"
            width=""
          ></el-table-column>
          <el-table-column
            prop="address"
            align="center"
            label="老板地址"
            width=""
          ></el-table-column>
          <el-table-column
            prop="area_name"
            align="center"
            label="片区"
            width=""
          ></el-table-column>
          <el-table-column
            prop="channel_name"
            align="center"
            label="渠道"
            width=""
          ></el-table-column>
        </el-table>
        <el-pagination
          :current-page="queryForm.pageNo"
          :layout="layout"
          :page-size="queryForm.pageSize"
          :total="totalCount"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
        <!-- 底部按钮 -->
        <div
          slot="footer"
          class="dialog-footer"
          style="margin-top: 5px; text-align: right"
        >
          <!-- <el-button type="primary" @click="save">确认</el-button> -->
          <el-button @click="close">取 消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getList, getSelect } from '@/api/chooseClientDialog'
  export default {
    name: 'ChooseClient',
    props: {
      ziobj: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        showDialog: false,
        loading: true,
        form: {
          area: [],
          channel: [],
        },
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true,
        },
        // 表格
        layout: 'total, sizes, prev, pager, next, jumper',
        totalCount: 0,

        queryForm: {
          pageNo: 1,
          pageSize: 10,
          area_id: [],
          channel_id: '',
          keyword: '',
          staff_id: '',
          class_id: '',
        },
        list: [
          {
            id: 1,
            goods_name: 'XXX超市',
            code: '',
            classes: '酒水1',
            brand: '',
            inventory: '****',
            units: '1箱12听',
            status: '正常',
          },
          {
            id: 1,
            goods_name: 'XXX超市123',
            code: '',
            classes: '酒水1111',
            brand: '',
            inventory: '****',
            units: '1箱12听',
            status: '1正常1',
          },
          {
            id: 1,
            goods_name: 'XXX烟酒',
            code: '',
            classes: '酒水221',
            brand: '',
            inventory: '****',
            units: '1箱12听',
            status: '正常',
          },
        ],
      }
    },
    watch: {
      // filterText(val) {
      //   this.$refs.tree.filter(val)
      // },
      showDialog(val) {
        if (val) {
          this.fetchData(this.queryForm)
          this.getSelectList()
        } else {
          this.queryForm = {
            pageNo: 1,
            pageSize: 10,
            area_id: [],
            channel_id: '',
            keyword: '',
            staff_id: '',
            class_id: '',
          }
        }
      },
    },
    methods: {
      getTreeData(data) {
        for (var i = 0; i < data.length; i++) {
          if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].children)
          }
        }
        return data
      },
      async fetchData(queryForm) {
        console.log('ffffff', this.ziobj)
        this.loading = true

        let { data, code, msg, totalCount } = await getList({
          ...queryForm,
          ...this.ziobj,
        })
        console.log(data, msg)
        this.list = data
        this.totalCount = totalCount
        this.loading = false
      },
      async getSelectList() {
        let { data, code, msg } = await getSelect()
        console.log(data, msg)
        if (code == 200) {
          this.form = data
          this.form.area = this.getTreeData(this.form.area)
        } else {
          this.$message.error(msg)
        }
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData(this.queryForm)
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData(this.queryForm)
      },

      close() {
        this.showDialog = false
        // this.$refs['addRowFrom'].resetFields() // 重置表单
      },
      treeClick(val) {
        console.log(val)
      },
      // save() {
      //   this.showDialog = false
      // },
      filter() {
        // this.$refs.tree.filter(this.filterText)
        console.log(111)
        console.log(this.queryForm.area_id)
        if (this.queryForm.area_id[0]) {
          this.queryForm.area_id =
            this.queryForm.area_id[this.queryForm.area_id.length - 1]
        } else {
          this.queryForm.area_id = ''
        }
        this.queryForm.pageNo = 1
        this.queryForm.pageSize = 10
        this.fetchData(this.queryForm)
      },
      filterNode(value, data) {
        if (!value) return true
        return data.label.indexOf(value) !== -1
      },
      setSelectRows() {},
      cellClick(row) {
        console.log(row)
        this.$emit('change-client', row)
        this.showDialog = false
      },
      add() {},
    },
  }
</script>

<style lang="scss" scoped>
  .tit {
    padding: 0 0 10px 0;
    text-align: center;
  }
</style>
